import React from "react";

import ThankYouIllustration from "assets/images/thank-you.png";

import "./FormSuccess.scss";

export default function FormSuccess() {
  return (
    <main className="form-access">
      <section className="page-wrapper">
        <div className="illustration">
          <img src={ThankYouIllustration} alt="Snapshot" />
        </div>
        <h1>Thank you</h1>
        <p>We’ll be in touch with you shortly.</p>
        <a href="/" className="cta primary">
          Back Home
        </a>
      </section>
    </main>
  );
}
