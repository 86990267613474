import React, {useState, useEffect} from "react"
import Modal from "react-modal"
import _ from "lodash"
import * as axios from "axios"

import EarlyAccessForm from "components/EarlyAccessForm"
import HeroSnapMain from "assets/videos/hero-snap-main.mp4"
import FeatureSnapOne from "assets/videos/feature-snap-one.mp4"
import FeatureSnapTwo from "assets/videos/feature-snap-two.mp4"
import MockupFrame from "assets/images/mockup-frame.png"
import HeroSnapBackground from "assets/images/hero-snap-background.png"
import HeroSnapMainPlaceholder from "assets/images/hero-snap-main.png"
import BulletSnapOne from "assets/images/bullet-snap-one.png"
import BulletSnapTwo from "assets/images/bullet-snap-two.png"
import JustPutItOnYourTabb from "assets/images/just-put-it-on-your-tabb.png"
import JustPutItOnYourTabbMobile from "assets/images/just-put-it-on-your-tabb-mobile.png"
import ContactIllustration from "assets/images/contact-illustration-dark.png"

import "./Home.scss"
import { generateUID } from "utils/helper"
import { BASE_API_URL } from "utils/constants"

export default function Home() {
  const [isHeroActive, setIsHeroActive] = useState(false)
  const [feedback, setFeedback] = useState("")

  const onSubmit = async () => {
    if (feedback) {
      const UUID = generateUID()
      const result = await axios.post(`${BASE_API_URL}/feedback`, { feedback, id: UUID })
      if (result.data && result.data.success) {
        window.location.href = "/thankyou"
      }
    } else {
      alert("Please enter a feedback.")
    }
  }

  const onFeedbackChange = (event) => {
    setFeedback(event.target.value)
  }

  useEffect(() => {
    window.addEventListener("scroll", _.throttle(handleHeroScroll, 100))
    return () => {
      window.removeEventListener("scroll", handleHeroScroll)
    }
  })

  const handleHeroScroll = () => {
    window.pageYOffset > "112" ? setIsHeroActive(true) : setIsHeroActive(false)
  }

  return (
    <main className="home">
      <section id="hero" className={`page-section hero ${isHeroActive ? "active" : null}`}>
        <div className="hero-wrapper">
          <div className="introduction">
            <h1 className="headline">
              The easiest way to<br />
              <span className="highlight">get your finances in order</span>
            </h1>
            <EarlyAccessForm />
          </div>
          <div className="snapshot">
            <img src={HeroSnapBackground} alt="Snapshot Background" className="snap-background" />
            <img src={HeroSnapMainPlaceholder} alt="Snapshot Main" className="snap-main-placeholder" />
            <video className="snap-main" width="320" height="690" preload="metadata" autoPlay playsInline loop muted>
              <source src={HeroSnapMain} type="video/mp4" />
            </video>
            <img src={MockupFrame} alt="Snapshot Frame" className="snap-frame" />
          </div>
        </div>
      </section>

      <section id="bullet-features" className="page-section bullet-features">
        <div className="bullet-features-wrapper">
          <div className="bullet-item">
            <div className="snapshot">
              <img src={BulletSnapOne} alt="Bullet Feature One" className="bullet-snap-one" />
            </div>
            <div className="description">
              <h3 className="title">Know how your money moves</h3>
              <p className="label">Tabb sends reminders at the end of each month so everyone can pay what they owe and go into a new month with a fresh start</p>
            </div>
          </div>
          <div className="bullet-item">
            <div className="snapshot">
              <img src={BulletSnapTwo} alt="Bullet Feature Two" className="bullet-snap-two" />
            </div>
            <div className="description">
              <h3 className="title">Split bills and pay your exact share</h3>
              <p className="label">Go dutch! With Tabb you'll be able to tap each item that you'll be paying for, and just send in that amount — tax and tip included.</p>
            </div>
          </div>
        </div>
      </section>

      <section
        id="catchy-text"
        className="page-section catchy-text"
      >
        <h1 className="headline">
          It's like having a<br />
          <span className="highlight">personal accountant</span>
          <br />
          in your pocket.<br />
        </h1>
      </section>

      <section id="features" className="page-section features">
        <div className="features-wrapper">
          <div className="feature-item">
            <div className="snapshot">
              <video className="snap-preview" width="320" height="690" preload="metadata" autoPlay playsInline loop muted>
                <source src={FeatureSnapOne} type="video/mp4" />
              </video>
              <img src={MockupFrame} alt="Snapshot Frame" className="snap-frame" />
            </div>
            <div className="description">
              <h2 className="title">Make smarter<br/><span className="highlight">spending decisions</span></h2>
              <p className="label">Tabb helps you build better spending habits by letting you set a monthly budget for yourself to keep track of how much you spend. If you go over your budget, we'll notify you so that you can find ways to save.</p>
            </div>
          </div>
          <div className="feature-item">
            <div className="snapshot">
              <video className="snap-preview" width="320" height="690" preload="metadata" autoPlay playsInline loop muted>
                <source src={FeatureSnapTwo} type="video/mp4" />
              </video>
              <img src={MockupFrame} alt="Snapshot Frame" className="snap-frame" />
            </div>
            <div className="description">
              <h2 className="title">A better way to make<br/><span className="highlight">shared online payments</span><br/>with friends</h2>
              <p className="label">No more remembering or reminding friends for paying their share! Simply add your online expense to Tabb and it will ensure that the dues are collected and paid on time!</p>
            </div>
          </div>
        </div>
      </section>

      <section
        id="just-put-it-on-your-tabb"
        className="page-section just-put-it-on-your-tabb"
      >
        <div className="tagline-wrapper desktop">
          <div className="tagline-image">
            <img src={JustPutItOnYourTabb} alt="Just put it on your Tabb" />
          </div>
        </div>
        <div className="tagline-wrapper mobile">
          <div className="tagline-image">
            <img src={JustPutItOnYourTabbMobile} alt="Just put it on your Tabb" />
          </div>
        </div>
      </section>

      <section id="contact" className="page-section contact">
      <div className="contact-wrapper">
        <div className="description">
          <h2>
            We’d love to hear
            <br />
            your thoughts
          </h2>
          <div className="blurb">
            Tabb is currently in it’s very initial stages of development. We are
            eager to know if you have any interesting ideas or specific set of
            features that you would want to see in the app. We will be launching
            our first release very soon.
          </div>
          <div className="email">
            <span>Write to us: </span>
            <a href="mailto:mail@tabb.app">mail@tabb.app</a>
          </div>
          {/* <form
            method="post"
            className="feedback-form"
            name="feedback-form"
            data-netlify-honeypot="bot-field"
            data-netlify="true"
            action="/thankyou"
          >
            <input type="hidden" name="form-name" value="feedback-form" /> */}
            <div className="feedback-form">
            <textarea
              id="feedback"
              className="input-value"
              name="feedback"
              rows={3}
              placeholder="Tell us if you have an idea or suggestion that we could work on for the upcoming builds..."
              onChange={onFeedbackChange}
              value={feedback}
            ></textarea>
            <input type="button" className="submit-button" value="Submit" onClick={onSubmit} />
            </div>
          {/* </form> */}
        </div>
        <div className="contact-illustration">
          <img src={ContactIllustration} alt="Snapshot" />
        </div>
        </div>
      </section>
    </main>
  )
}

Modal.setAppElement("#root")
