import React from "react"

import EarlyAccessForm from "components/EarlyAccessForm"
import EarlyAccessIllustration from "assets/images/early-access-illustration.png"

import "./EarlyAccess.scss"

export default function EarlyAccess() {
  return (
    <main className="early-access">
      <section className="notification-form">
        <div className="illustration">
          <img src={EarlyAccessIllustration} alt="Snapshot" />
        </div>
        <h1>We're launching soon!</h1>
        <p>
          Be one of the firsts to get notified when we are ready to roll out
          early previews of Tabb. We won’t spam you — it’s a promise!
        </p>
        <EarlyAccessForm />
      </section>
    </main>
  )
}
