import React from "react";

import "./Footer.scss";

export default function Header() {
  return (
    <footer className="footer">
      <div className="wrapper">
        <div className="attributions">
          <span>Illustrations by the amazing&nbsp;</span>
          <a
            href="https://twitter.com/madebyabsurd"
            target="_blank"
            rel="noopener noreferrer"
          >
            @madebyabsurd
          </a>
        </div>
      </div>
    </footer>
  );
}
