import React from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Header from "./components/Header"
import Footer from "./components/Footer"
import Home from "./pages/Home"
import EarlyAccess from "./pages/EarlyAccess"
import FormSuccess from "./pages/FormSuccess"
import "./Site.css"

function Site() {
  return (
    <Router>
      <div id="site" className="Site">
        <Header />
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/early-access" component={EarlyAccess} />
          <Route path="/thankyou" component={FormSuccess} />
        </Switch>
        <Footer />
      </div>
    </Router>
  )
}

export default Site
