import React, { useEffect, useState } from "react"
import * as axios from "axios"
import Modal from "react-modal"
import Lottie from "react-lottie"
import EarlyAccessForm from "components/EarlyAccessForm"
import { ReactComponent as CaretUp } from "assets/icons/caret-up.svg"
import { ReactComponent as Cross } from "assets/icons/cross.svg"
import loadingLottie from "assets/lottie/loading.json"

import "./UpvoteCounter.scss"
import { Fragment } from "react"
import { BASE_API_URL } from "utils/constants"
import { generateUID } from "utils/helper"

// const COUNTER_KEY = "d8b808c8-24fa-4dc4-a73f-11fb01933a57"

const UUID = generateUID()

export default function UpvoteCounter(props) {
  // Quick Feedback Modal

  const [modalIsOpen, setIsOpen] = React.useState(false)
  const [loading, setIsLoading] = React.useState(false)
  const [counter, setCounter] = React.useState(0)

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  // Upvote Counter
  // const uid = getCookie(UID_COOKIE_NAME)

  const [isUpvoted, setIsUpvoted] = useState(false)

  const handleUpvote = async (e) => {
    try {
      e.preventDefault()
      setIsLoading(true)
      !isUpvoted ? openModal() : closeModal()
      const result = await axios.get(`${BASE_API_URL}/toggleVote?id=${UUID}`)
      setCounter(result.data.count)
      setIsLoading(false)
    } catch (e) {
      console.log(e)
    }

    setIsUpvoted(!isUpvoted)
  }

  useEffect(() => {
    const asyncFunction = async () => {
      // const result = await countapi.get("tabb", COUNTER_KEY)
      // setCounter(result.value)
      try {
        const result = await axios.get(
          `${BASE_API_URL}/getCountAndHasUserVoted?id=${UUID}`
        )
        setCounter(result.data.count)
        setIsUpvoted(result.data.hasVoted)
      } catch (e) {
        console.log(e)
      }
    }
    asyncFunction()
  }, [])

  return (
    <div
      className={`upvote-and-quick-feedback-wrapper ${
        props.isHeaderComponent ? "header-component" : ""
      }`}
    >
      <form id="upvote-counter" className="upvote-counter">
        <button
          type="submit"
          className={`upvote-button ${isUpvoted && "active"}`}
          onClick={handleUpvote}
        >
          {
            <div className="counter">
              {loading ? (
                <i className="loader"><Lottie width={20} height={20} options={{ animationData: loadingLottie }} /></i>
              ) : (
                <Fragment>
                  <CaretUp />
                  <span className="value">{counter}</span>
                </Fragment>
              )}
            </div>
          }
          <div className="label">
            <div className="title">Upvote to show interest</div>
            <div className="description">
              This helps us reach our goal of launching the app faster
            </div>
          </div>
        </button>
      </form>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Would you like to share more"
        portalClassName="quick-feedback-modal-portal"
        overlayClassName="quick-feedback-modal-overlay"
        bodyOpenClassName="quick-feedback-modal__body-open"
        htmlOpenClassName="quick-feedback-html-open"
        parentSelector={() => document.querySelector("#upvote-counter")}
        aria={{
          labelledby: "Would you like to share more?",
          describedby:
            "Tell us if you have an idea or suggestion that we could work on for the upcoming builds.",
        }}
      >
        {/* <form
          method="post"
          className="quick-feedback"
          name="quick-feedback-form"
          data-netlify-honeypot="bot-field"
          data-netlify="true"
          action="/thankyou"
        >
          <input type="hidden" name="form-name" value="quick-feedback-form" />
          <i className="close-button">
            <Cross onClick={closeModal} />
          </i>
          <label for="quick-feedback" className="title">
            Would you like to share more?
          </label>
          <textarea
            id="quick-feedback"
            className="input-value"
            name="quick-feedback"
            rows={3}
            placeholder="Tell us if you have an idea or suggestion that we could work on for the upcoming builds..."
          ></textarea>
          <input type="submit" className="submit-button" value="Submit" />
        </form> */}
        <div className="early-access-popup">
          <i className="close-button">
            <Cross onClick={closeModal} />
          </i>
          <label htmlFor="quick-feedback" className="title">
            Join the wailist to get early access!
          </label>
          <EarlyAccessForm />
        </div>
      </Modal>
    </div>
  )
}
