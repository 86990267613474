import React from "react"
import Lottie from "react-lottie"
import * as axios from "axios"

import Button from "components/interface/Button"
import arrowRightCircleLottie from "assets/lottie/arrow-right-circle.json"
import loadingLottie from "assets/lottie/loading.json"

import "./EarlyAccessForm.scss"
import { BASE_API_URL } from "utils/constants"
import { useState } from "react"
import { generateUID, validateEmail } from "utils/helper"

export default function EarlyAccessForm(props) {
  const [email, setEmail] = useState("")
  const [loading, setIsLoading] = React.useState(false)

  const onEarlyAccessFormSubmit = async () => {
    try {
      if (email && validateEmail(email)) {
        setIsLoading(true)
        const UUID = generateUID()
        const result = await axios.post(`${BASE_API_URL}/earlyAccess`, {
          email,
          id: UUID,
        })
        if (result.data && result.data.success) {
          window.location.href = "/thankyou"
        } else {
          setEmail("")
          alert("Something went wrong! Please try again later.")
        }
        setIsLoading(false)
      } else {
        alert("Invalid Email")
      }
    } catch (e) {
      console.log(e)
      setEmail("")
      setIsLoading(false)
      alert("Something went wrong! Please try again later.")
    }
  }

  const onEmailChange = (event) => {
    setEmail(event.target.value)
  }

  return (
    <div className={`early-access-form ${props.source}`}>
      <div className="form-inputs">
        <label htmlFor="email">Try out an early access build</label>
        <input
          id="email"
          type="email"
          name="email"
          required={true}
          placeholder={
            props.placeholder
              ? props.placeholder
              : "Enter your email to join the waitlist"
          }
          onChange={onEmailChange}
          value={email}
        />

        <Button onClick={onEarlyAccessFormSubmit}>
          {loading ? (
            <i className="loader">
              <Lottie
                width={20}
                height={20}
                options={{ animationData: loadingLottie }}
              />
            </i>
          ) : (
            <Lottie
              options={{
                loop: false,
                autoplay: false,
                isStopped: true,
                animationData: arrowRightCircleLottie,
              }}
            />
          )}
        </Button>
      </div>
    </div>
  )
}
