import React from "react";
import "./Button.scss";

export default function Header(props) {
  return (
    <button
      className={`button primary ${props.className || null}`}
      type={props.type || "button"}
      disabled={props.disabled}
      onClick={props.onClick || null}
    >
      <span className="label">{props.children}</span>
    </button>
  );
}
