import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import _ from "lodash"
import UpvoteCounter from "components/UpvoteCounter"

import Logo from "assets/logo.png"

import "./Header.scss"

export default function Header() {
  const [isSticky, setIsSticky] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", _.throttle(handleScroll, 100))
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  })

  const handleScroll = () => {
    window.pageYOffset > "64" ? setIsSticky(true) : setIsSticky(false)
  }

  return (
    <header id="header" className={`header ${isSticky ? "sticky" : ""}`}>
      <div className="wrapper">
        <Link to="/" className="logo">
          <img src={Logo} alt="Tabb Logo" />
        </Link>
        <nav className="navigation">
          {/* <a href="/#invest" className="item">
            Invest
          </a> */}
          <a href="/#contact" className="item">
            Contact
          </a>
          <Link to="/early-access" className="item">
            Early Access
          </Link>
        </nav>
        <UpvoteCounter isHeaderComponent={true} />
      </div>
    </header>
  )
}
